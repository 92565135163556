import { mapState } from 'vuex'
import breakpointsMixin from '@/mixins/breakpointsMixin'
import vuexMixin from '@/mixins/vuexMixin'

export default {
  components: {
    CMTable: () => import('@/components/Common/CMTable.vue')
  },

  computed: {
    ...mapState({
      currentTab: ({ measurements }) => measurements.currentTab?.title,
      currentSelector: ({ measurements }) => measurements.currentSelector,
      inventory: ({ measurements }) => measurements.inventory || [],
      loading: ({ measurements }) => measurements.loading,
      mapReady: ({ measurements }) => measurements.mapReady,
      isAllSelected: ({ measurements }) => measurements.isAllSelected
    })
  },

  mixins: [breakpointsMixin, vuexMixin],

  watch: {
    currentTab: {
      deep: true,
      handler () {
        this.setVuexElement({
          path: 'measurements.feedback',
          value: {}
        })
      }
    }
  },

  methods: {
    updateSelected (selection = []) {
      if (!this.isAllSelected || !this.isAllSelected.length) {
        const dictionary = {
          ST: 'station',
          TR: 'station',
          SU: 'station',
          FE: 'fuse',
          AS: 'station',
          LI: 'line',
          CP: 'connectionPoint',
          SP: 'connectionPoint',
          SM: 'connectionPoint'
        }

        const elementType = dictionary[this.currentSelector.short]
        const inventoryIds = this.inventory.map(item => item.ID)
        const filteredSelected = selection.filter(item => inventoryIds.includes(item.ID))

        this.setVuexElement({
          path: 'measurements.selected',
          value: {
            [this.currentSelector.short]: filteredSelected.map(item => {
              const elementIdOptions = {
                TR: item.STATION,
                SM: item.CGP,
                SP: item.CGP,
                AS: item.STATION
              }

              return {
                station: item.STATION || item.ID,
                elementType,
                elementId: elementIdOptions[this.currentSelector.short] || item.ID,
                origin: 'MS_TABLE',
                ...(this.currentSelector.tabs.includes('Measurements') && { meterId: item.ID }),
                ...(this.currentSelector.short === 'FE' && { lines: item.LINES }),
                ...item
              }
            })
          }
        })
      }
    },

    toggleLoading () {
      this.setVuexElement({
        path: 'measurements.loading',
        value: !this.loading
      })
    },

    clearTimeSelection () {
      this.setVuexElement({
        path: `measurements.timeSelection.${this.currentTab}`,
        value: { start: null, end: null }
      })
    }
  }
}
