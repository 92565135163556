import { mapState } from 'vuex'

export default {
  data: () => ({
    aggregation: null
  }),

  components: {
    CMDatePicker: () => import('@/components/Common/CMDatePicker.vue')
  },

  watch: {
    feedback: {
      deep: true,
      handler (val) {
        if (val.visible) {
          this.hideAlert()
        }
      }
    }
  },

  computed: {
    ...mapState({
      selected: ({ measurements }) => measurements.selected[measurements.currentSelector?.short] || [],
      timeSelection: ({ measurements }) => measurements.timeSelection[measurements.currentTab?.title],
      plotData: ({ measurements }) => measurements.plotData,
      type: ({ measurements }) => measurements.currentSelector.short,
      feedback: ({ measurements }) => measurements.feedback,
      currentTabTitle: ({ measurements }) => measurements.currentTab?.title || [],
      selectedStationsIds: ({ measurements }) => measurements.selectedStationsIds || []
    })
  },

  methods: {
    updateDate (date, identifier) {
      const timeSelection = {
        ...this.timeSelection,
        [identifier]: date
      }
      if (timeSelection.end) {
        let endDate = new Date(timeSelection.end)
        let epochEndDate = endDate.getTime()
        epochEndDate = epochEndDate + (24 * 3600 * 1000) - (this.$STEP_TIME * 1000)
        endDate = new Date(epochEndDate)
        timeSelection.end = endDate.toISOString().slice(0, -5) + 'Z'
      }

      this.setVuexElement({
        path: `measurements.timeSelection.${this.currentTabTitle}`,
        value: timeSelection
      })
    },

    setDisabledSendToPlot (entity, modifier) {
      const anyDateEmpty = Object.values(this.timeSelection).some(time => !time)

      return !entity ||
        !this.selected.length ||
        !modifier ||
        !this.aggregation ||
        anyDateEmpty
    },

    onToggleSelectAll (items) {
      const storedSelected = this.$store.state.measurements.selected

      const dictionary = {
        ST: 'station',
        TR: 'station',
        SU: 'station',
        FE: 'fuse',
        AS: 'station',
        LI: 'line',
        CP: 'connectionPoint',
        SP: 'connectionPoint',
        SM: 'connectionPoint'
      }
      const elementType = dictionary[this.currentSelector.short]

      const itemsWithType = items.map((item) => {
        const elementIdOptions = {
          TR: item.STATION,
          SM: item.CGP,
          SP: item.CGP,
          AS: item.STATION
        }
        return {
          station: item.STATION || item.ID,
          elementType,
          elementId: elementIdOptions[this.currentSelector.short] || item.ID,
          origin: 'MS_TABLE',
          ...(this.currentSelector.tabs.includes('Measurements') && { meterId: item.ID }),
          ...(this.currentSelector.short === 'FE' && { lines: item.LINES }),
          ...item
        }
      })

      this.setVuexElement({
        path: 'measurements.isAllSelected',
        value: itemsWithType
      })

      this.setVuexElement({
        path: 'measurements.selected',
        value: itemsWithType.length
          ? { ...storedSelected, [this.type]: itemsWithType }
          : { ...storedSelected, [this.type]: [] }
      })
    },

    hideAlert () {
      window.setTimeout(() => {
        this.setVuexElement({
          path: 'measurements.feedback',
          value: {}
        })
      }, 6000)
    },

    clearSelection () {
      this.setVuexElement({
        path: 'measurements.selected',
        value: {
          ...this.selected,
          [this.type]: []
        }
      })

      this.setVuexElement({
        path: 'measurements.isAllSelected',
        value: true
      })
    }
  }
}
